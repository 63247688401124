import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({
  address,
  copyright,
  contact,
  privacyPolicyLink,
  termsConditionsLink,
  unsubscribeText,
  unsubscribeLinkText,
  unsubscribeTextAfterLinkText
}) => {

  return (
    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
      <tbody>
        <tr>
          <td valign="top" align="center">
            <table width="700" border="0" cellSpacing="0" cellPadding="0" align="center" className="EmailTemplate--section--outerContainer ao_wrapper">
              <tbody>
                <tr>
                  <td align="center" valign="top" className="EmailTemplate--section--outerPadding">
                    <table width="620" border="0" cellSpacing="0" cellPadding="0" className="EmailTemplate--section--innerContainer ao_wrapper">
                      <tbody>
                        <tr>
                          <td height="30" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td height="1" bgcolor="#b8b8ae" className="EmailTemplate--emptySpace"><img src="http://hosting.fyleio.com/29159/public/spacer.gif" width="1" alt="" className="EmailTemplate--spacer" /></td>
                        </tr>
                        <tr>
                          <td height="60" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td valign="top" align="center">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
                              <tbody>
                                <tr>
                                  <td align="center">
                                    <table align="center" border="0" cellPadding="0" cellSpacing="0" className="Footer--topLinksContainer">
                                      <tbody>
                                        <tr>
                                          <td align="center" className="Footer--topLinks">
                                            {privacyPolicyLink && (
                                              <a href={privacyPolicyLink.url} target="_blank" rel="noopener noreferrer" className="EmailTemplate--links">
                                                {privacyPolicyLink.label}
                                              </a>
                                            )}
                                            &nbsp;|&nbsp;
                                            {termsConditionsLink && (
                                              <a href={termsConditionsLink.url} target="_blank" rel="noopener noreferrer" className="EmailTemplate--links">
                                                {termsConditionsLink.label}
                                              </a>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td height="10" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center" valign="top">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="left">
                              <tbody>
                                <tr>
                                  {(address && copyright && contact) && (
                                    <td align="center" className="footer Footer--bottomLinks">
                                      {address}
                                      <br />
                                      {copyright}
                                      <br />
                                      {contact}
                                      <a className="Footer--contactLink" href="mailto:Customer.Insight@aesop.com" title="Link to mailto:Customer.Insight@aesop.com">contact us</a>.
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td height="10" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="left">
                            <tbody>
                              <tr>
                                <td align="center" className="footer Footer--bottomLinks">
                                  {unsubscribeText}
                                  <span>
                                    <a href="%tag_unsubscribe_url%">{unsubscribeLinkText}</a>
                                  </span>
                                  {unsubscribeTextAfterLinkText}.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </tr>
                        <tr>
                          <td height="100" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

Footer.propTypes = {
  address: PropTypes.string,
  copyright: PropTypes.string,
  contact: PropTypes.string,
  privacyPolicyLink: PropTypes.object,
  termsConditionsLink: PropTypes.object
};

export default Footer;