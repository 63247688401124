import React from 'react';
import PropTypes from 'prop-types';

const BulletproofCTA = ({ label, url }) => {
  const generateSurveyUrlQuery = `${url}?store_no={{store_no}}&sbs_no={{sbs_no}}&person_uuid={{person_uuid}}&order_type={{order_type}}`;
  return (
    <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="left">
      <tbody>
        {/* <!-- Bulletproof CTA -->
        <!-- Bulletproof CTA --> */}
        <tr>
          <td align="left" className="BulletproofCTA--padding">
            <table cellPadding="0" cellSpacing="0" border="0" align="left" className="BulletproofCTA">
              <tbody>
                <tr>
                  <td valign="top" bgcolor="#333333" className="BulletproofCTA--labelMainStyling">
                    <div>
                      <a href={generateSurveyUrlQuery} className="BulletproofCTA--labelInnerStyling">{label}</a>
                    </div>
                  </td>
                  <td valign="top" bgcolor="#333333" className="BulletproofCTA--arrowContainer">
                    <a href={generateSurveyUrlQuery} className="BulletproofCTA--iconInnerStyling">
                      <img width="15" src="https://hosting.fyleio.com/35578/public/templates/arrow-white.png" alt="" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        {/* <!-- End Bulletproof CTA -->
        <!-- End Bulletproof CTA --> */}
      </tbody>
    </table>
  );
};

BulletproofCTA.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default BulletproofCTA;
